<template>
  <div class="ProductRelatedProductsList">
    <div class="flex flex-col">
      <div
        v-for="(product, index) in list"
        :key="'related-product-' + index"
        class="py-6 flex w-full"
      >
        <nuxt-link :to="productUrl(product.url)" class="relative w-40 h-40 flex items-center justify-center mr-12 overflow-hidden">
          <img
            v-if="!product.image"
            src="/icons/no-image.svg"
            class="object-contain"
            alt="no image"
          >
          <nuxt-img
            v-else
            :src="product.image"
            class="object-contain max-h-32"
            preset="standard"
            provider="norce"
            sizes="40px"
            loading="lazy"
          />
          <div class="imageOverlay absolute top-0 left-0 w-full h-full" />
        </nuxt-link>

        <div class="flex justify-between flex-1 type-xs">
          <div
            class="w-full"
            :class="{
              'w-3/5': showBuyBtns
            }"
          >
            <nuxt-link :to="productUrl(product.url)" class="type-sm">
              {{ product.name }}
            </nuxt-link>
            <div class="text-dark">
              {{ product.partNo }}
            </div>
          </div>
          <div
            v-if="showBuyBtns"
            class="text-right flex items-center"
          >
            <button
              v-if="userStore.getPrice(pricePartNo(product)).status === 'notLoggedIn'"
              class="btn--text btn--mob"
              @click.prevent="showLogin"
            >
              {{ $t('priceInfo.loginForPriceInfo') }}
            </button>
            <div
              v-if="userStore.getPrice(pricePartNo(product)).status === 'ok'"
              class="inline-block"
            >
              <div
                class="type-sm-medium whitespace-nowrap"
                :class="{
                  'text-criticalDark': userStore.getPrice(pricePartNo(product)).price?.type !== NorcePriceTypes.Standard
                }"
              >
                {{ userStore.getPrice(pricePartNo(product)).price?.priceBeforeVatDisplay }}
              </div>

              <p v-if="isCustomerPrice(product) || isCampaignPrice(product)" class="type-base-medium text-12 text-dark line-through whitespace-nowrap">
                {{ userStore.getPrice(pricePartNo(product)).price?.priceBeforeDiscountBeforeVatDisplay }}
              </p>
            </div>

            <button
              v-if="userStore.isLoggedIn && !product.extendedLayout"
              class="ml-12 bg-darkest h-40 w-40 text-center flex items-center justify-center hover:bg-darker transition-all"
              :title="$t('quickbuy.button')"
              @click="openQuickbuy(product)"
            >
              <img
                src="/icons/plus-inv.svg"
                class="w-20 h-20 inline-block"
                alt=""
              >
            </button>
          </div>
        </div>
      </div>

      <div v-if="showBuyBtns">
        <Tooltip
          v-if="userStore.isFinanceUser"
          :show-on-hover="true"
          :text="$t('priceInfo.financeUserTooltip')"
        >
          <button

            class="btn w-full mb-12 mt-6"
            :class="{
              'loading': loading,
            }"
            :disabled="true"
          >
            {{ $t('productPage.sizeSelector.addToCartBtn') }}
            <span v-if="totalPrices[0] > 0" class="text-12 ml-12 normal-case">
              {{ useLocalizedCurrency(Number(totalPrices[0])) }}
            </span>
            <span v-if="totalPrices[1] > totalPrices[0]" class="text-12 text-dark ml-6 line-through normal-case">
              {{ useLocalizedCurrency(Number(totalPrices[1])) }}
            </span>
          </button>
        </Tooltip>
        <button
          v-else-if="userStore.isLoggedIn"
          class="btn w-full mb-12 mt-6"
          :class="{
            'loading': loading,
          }"
          @click="addAllToCart"
        >
          {{ $t('productPage.sizeSelector.addToCartBtn') }}
          <span v-if="totalPrices[0] > 0" class="text-12 ml-12 normal-case">
            {{ useLocalizedCurrency(Number(totalPrices[0])) }}
          </span>
          <span v-if="totalPrices[1] > totalPrices[0]" class="text-12 text-dark ml-6 line-through normal-case">
            {{ useLocalizedCurrency(Number(totalPrices[1])) }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import useLinkReplacer from '~/composeables/useLinkReplacer';
import { useLocalizedCurrency } from '~/composeables/useLocalizedCurrency';
import { ProductModel } from '~/models/product';
import { useUserStore } from '~/store/user';
import { useUiStore } from '~/store/ui';
import { useCartStore } from '~/store/cart';
import { NorcePriceTypes } from '~/constants/norceCodes';
import Tooltip from '~/components/body/Tooltip.vue';

const { productUrl } = useLinkReplacer();

const { $toNumber } = useNuxtApp();

const userStore = useUserStore();
const cartStore = useCartStore();
const uiStore = useUiStore();

const props = defineProps<{
  list: ProductModel[],
  showBuyBtns: boolean
}>();

const emit = defineEmits<{
  (e: 'addToCart'): void;
  (e: 'addingToCart'): void;
}>();

const openQuickbuy = async(product: ProductModel) => {
  cartStore.setCurrentlyBuying(product);
  uiStore.setShowQuickBuy(true);
};

const totalPrices = computed((): number[] => {
  const total = [0,0] as number[];
  props.list.forEach((p) => {
    const partNo = p.hasVariants ? p.variants[0].partNo : p.partNo;
    if (userStore.getPrice(partNo).status === 'ok') {
      const price = userStore.getPrice(partNo).price;
      if (price) {
        total[0] += $toNumber(price.priceBeforeVat) ?? 0;
        total[1] += $toNumber(price.isStandardPrice ? price.priceBeforeVat : price.priceBeforeDiscountBeforeVat) ?? 0;
      }
    }
  });

  return total;
});

const pricePartNo = ((product: ProductModel): string  => {
  if (product.hasVariants) {
    return product.variants[0].partNo;
  }
  return product.partNo;
});

const isCustomerPrice = ((product: ProductModel): boolean => {
  return userStore.getPrice(pricePartNo(product)).price.isCustomerPrice;
});

const isCampaignPrice = ((product: ProductModel): boolean => {
  return userStore.getPrice(pricePartNo(product)).price.isCampaignPrice;
});

const loading = ref(false);
const addAllToCart = async() => {
  loading.value = true;
  emit('addingToCart');

  let addedToCart = 0;
  await props.list.reduce(async(a, p) => {
    await a;
    const res = await cartStore.updateCart(
      p.variants[0].partNo,
      1,
      -1,
      props.list.length === 1,
      p.variants[0]
    );

    if (res) {
      addedToCart++;
    }
  }, Promise.resolve());

  if (addedToCart > 1) {
    cartStore.setAddedToCartCount(addedToCart);
    uiStore.setShowAddedToCart(true);
  }

  loading.value = false;
};

const showLogin = () => {
  uiStore.setShowSigninModal(true, true);
};

</script>
