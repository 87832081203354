<template>
  <div class="NameAndCategory flex flex-col justify-start gap-8">
    <div class="flex justify-between type-tagline">
      <div v-if="globalContent.getBrandLink(productItem.brand.code)" class="dotted-border">
        <nuxt-link
          :to="globalContent.getBrandLink(productItem.brand.code) || ''"
        >
          {{ productItem.brand.name }}
        </nuxt-link>
      </div>

      <p v-if="showPartNo" class="font-normal text-dark">
        {{ $t('productPage.partNo.short') }} {{ activeVariant.partNo }}
      </p>
    </div>

    <div v-if="productItem.isHiddenProduct" class="flex items-center my-16">
      <Tooltip
        v-if="showPartNo"
        :text="$t('fastorder.table.hiddenProduct')"
        :show-on-hover="true"
      >
        <img
          alt=""
          src="/icons/eye-closed.svg"
          class="w-[16px] h-[16px]"
        >
      </Tooltip>
      <img
        v-else
        alt=""
        src="/icons/eye-closed.svg"
        class="w-[16px] h-[16px]"
      >
    </div>

    <h1 class="type-headline-lg desk:type-headline-xl mt-8 desk:mt-16" v-text="productItem.extendedLayout ? productItem.name : activeVariant.name" />

    <div
      v-if="showDescription && description"
      class="ProductDescription wysiwyg type-sm"
      v-html="description"
    />
  </div>
</template>

<script setup lang="ts">
import { ProductModel } from '~/models/product';
import ProductVariant from '~/models/productVariant';
import { useGlobalContentStore } from '~/store/globalContent';
import Tooltip from '~/components/body/Tooltip.vue';
const globalContent = useGlobalContentStore();

interface Props {
  productItem: ProductModel,
  activeVariant: ProductVariant,
  showPartNo?: boolean;
  showDescription?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  showPartNo: true,
  showDescription: true,
});
// Makes sure brands are loaded
onMounted(()=> {
  globalContent.loadBrands();
});

const description = computed(()=> {
  if (props.productItem.extendedLayout && props.productItem.description) {
    return props.productItem.description;
  }
  if (!props.productItem.extendedLayout && props.productItem.shortDescription) {
    return props.productItem.shortDescription;
  }
  return null;
});

</script>

<style scoped lang="postcss">
</style>
